import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useBackgroundImage } from './utils/backgroundHelper';

const AppWrapper = () => {
  const backgroundUrl = useBackgroundImage();
  
  // Ustawiamy zmienną CSS z URL tła
  React.useEffect(() => {
    document.documentElement.style.setProperty('--background-image-url', backgroundUrl ? `url(${backgroundUrl})` : 'none');
  }, [backgroundUrl]);

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

reportWebVitals();
