import { useEffect, useState } from 'react';

// Importujemy wszystkie dostępne tła
const backgroundImages = require.context('../assets/images', false, /background_.*\.jpg$/);

export const useBackgroundImage = () => {
  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    const subdomain = window.location.hostname.split('.')[0];
    const expectedImageName = `background_${subdomain}.jpg`;
    
    try {
      // Sprawdzamy czy istnieje tło dla danej subdomeny
      const imageUrl = backgroundImages.keys().find(key => key.endsWith(expectedImageName));
      if (imageUrl) {
        setBackgroundUrl(backgroundImages(imageUrl));
      } else {
        setBackgroundUrl('');
      }
    } catch (error) {
      console.error('Error loading background image:', error);
      setBackgroundUrl('');
    }
  }, []);

  return backgroundUrl;
};
